.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 75px;
  background-color: #fff;
  transition: all 0.3s ease;
  overflow-x: hidden;
  z-index: 9999000;
}

.sidebar.open {
  width: 350px;
}

/* Main content styles */
.main {
  margin-left: 75px;
  width: calc(100% - 75px);
  transition: all 0.3s ease;
}

.main.sidebar-open {
  margin-left: 350px;
  width: calc(100% - 350px);
}

/* Sidebar menu styles */
.sidebar-menu > ul {
  margin: 10px 35px 10px 30px;
  padding: 20px 0;
  border-radius: 10px;
  background: #f5f7fa;
}

.sidebar-menu > li {
  text-decoration: none;
  color: #40475c;
  font-size: 18px;
  border-radius: 3px;
  line-height: 20px;
  transition: all 0.2s ease-in-out;
}

.sidebar-menu > li:hover,
.sidebar-menu > li.active {
  color: #1b83c5;
}

.sidebar-menu > li:hover {
  padding-left: 20px;
}

.sidebar-menu > li:hover > i:not(.has-sub-menu).material-icons-two-tone,
.sidebar-menu > ul > li.active > i:not(.has-sub-menu).material-icons-two-tone {
  filter: invert(42%) sepia(85%) saturate(468%) hue-rotate(172deg)
    brightness(101%) contrast(98%);
}

.sidebar-menu .sub-menu {
  display: block;
  padding-left: 20px;
  margin-top: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.sidebar-menu .sub-menu li {
  padding: 8px;
  cursor: pointer;
}

.close-icon {
  display: none;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  /* Reset main content position */
  .main {
    margin-left: 0 !important;
    width: 100% !important;
    transition: none;
  }

  .main.sidebar-open {
    margin-left: 0 !important;
    width: 100% !important;
  }

  /* Adjust sidebar for mobile */
  .sidebar {
    display: none;
    left: -75px;
    box-shadow: none;
  }

  .close-icon {
    display: inline-block;
  }

  .sidebar.open {
    display: block;
    left: 0;
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }

  /* Adjust sidebar content width */
  .sidebar.open .sidebar-header,
  .sidebar.open .sidebar-menu {
    width: 300px;
    background: #fff;
  }

  /* Hide main content scrolling when sidebar is open */
  body.sidebar-open {
    overflow: hidden;
  }
}

/* Add additional mobile optimizations */
@media (max-width: 480px) {
  .sidebar.open {
    width: 100%;
  }

  .sidebar-menu > ul {
    margin: 10px 20px;
  }

  .sidebar-menu > li {
    font-size: 16px;
  }
}
