.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99000;
}

.auth-modal {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  text-align: center;
  position: relative;
}

.auth-modal h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.auth-modal p {
  font-size: 24px;
  font-weight: 400;
  width: 72%;
  margin: 20px auto;
}

.auth-modal-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.auth-modal-buttons button {
  width: 50%;
}

.login {
  padding: 18px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover,
.btn-secondary:hover {
  opacity: 0.9;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 760px) {
  .auth-modal-buttons {
    flex-direction: column;
  }

  .auth-modal-buttons button {
    width: 70%;
  }

  .auth-modal h2 {
 font-size: 20px;
}

.auth-modal p {
  font-size:14px;
  width:100%;
}
}
