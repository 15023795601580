.icon-div {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  background: #f4f7fa;
  border-radius: 10px;
}

.title-card {
  text-transform: uppercase;
  font-weight: 500;
  color: #a1a5b5;
  font-size: 16px;
}

.stat {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #27323f;
}

.custom-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
