.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 20px;
}

.step {
  width: 30%;
  height: 5px;
  background-color: #ccc;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.step.active {
  background-color: #337ab7;
}
