@-webkit-keyframes jump {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40% {
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes jump {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40% {
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes rotated {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes rotated {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@-webkit-keyframes rotatedHalf {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@keyframes rotatedHalf {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@-webkit-keyframes rotatedHalfTwo {
    0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes rotatedHalfTwo {
    0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}


@-webkit-keyframes scale-upOne {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
    }
}

@keyframes scale-upOne {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
    }
}

@-webkit-keyframes scale-right {
    0% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    50% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
    }

    100% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@keyframes scale-right {
    0% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    50% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
    }

    100% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0.5;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0.5;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}


@keyframes hvr-ripple-out {
    0% {
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 1;
    }

    100% {
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        opacity: 0;
    }
}

@keyframes hvr-ripple-out-two {
    0% {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
    }

    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

@-webkit-keyframes scale-up-one {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-one {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


@-webkit-keyframes scale-up-one-1 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-one-1 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


@-webkit-keyframes scale-up-two {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    40% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}

@keyframes scale-up-two {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    40% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}

@-webkit-keyframes scale-up-three {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    40% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    100% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
}

@keyframes scale-up-three {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    40% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    100% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
}

@-webkit-keyframes scale-up-four {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    40% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes scale-up-four {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    40% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@-webkit-keyframes scale-up-five {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes scale-up-five {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}


@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}


@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}



@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}


@-webkit-keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}


@keyframes animationFramesThree {
    0% {
        transform: translate(165px, -179px);
    }

    100% {
        transform: translate(-346px, 617px);
    }
}


@-webkit-keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
    }

    100% {
        -webkit-transform: translate(-346px, 617px);
    }
}


@keyframes animationFramesFour {
    0% {
        transform: translate(-200px, 151px) rotate(0deg);
    }

    100% {
        transform: translate(251px, -200px) rotate(180deg);
    }
}


@-webkit-keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-200px, 151px) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
    }
}


@keyframes animationFramesFive {
    0% {
        transform: translate(61px, -99px) rotate(0deg);
    }

    21% {
        transform: translate(4px, -190px) rotate(38deg);
    }

    41% {
        transform: translate(-139px, -200px) rotate(74deg);
    }

    60% {
        transform: translate(-263px, -164px) rotate(108deg);
    }

    80% {
        transform: translate(-195px, -49px) rotate(144deg);
    }

    100% {
        transform: translate(-1px, 0px) rotate(180deg);
    }
}


@-webkit-keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0deg);
    }

    21% {
        -webkit-transform: translate(54px, -90px) rotate(38deg);
    }

    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
    }

    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(-1px, 0px) rotate(180deg);
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@-webkit-keyframes gradientBG {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@keyframes imageBgAnim {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 120% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@-webkit-keyframes gradientBG {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 120% 0;
    }

    100% {
        background-position: 0 0;
    }
}

/* Move frame*/
@keyframes moveRound {
    0% {
        background-color: #eee3ff;
        left: -24px;
        top: 0px;
    }

    25% {
        background-color: #feddfc;
        left: 100px;
        top: 0px;
    }

    50% {
        background-color: #efe4ff;
        left: 100px;
        top: 100px;
    }

    75% {
        background-color: #e2fcf4;
        left: -24px;
        top: 200px;
    }

    100% {
        background-color: #eee3ff;
        left: -24px;
        top: 0px;
    }
}

@-webkit-keyframes moveRound {
    0% {
        background-color: #eee3ff;
        left: -24px;
        top: 0px;
    }

    25% {
        background-color: #feddfc;
        left: 100px;
        top: 0px;
    }

    50% {
        background-color: #efe4ff;
        left: 100px;
        top: 200px;
    }

    75% {
        background-color: #e2fcf4;
        left: -24px;
        top: 100px;
    }

    100% {
        background-color: #eee3ff;
        left: -24px;
        top: 0px;
    }
}

@keyframes moveShap {
    0% {
        left: 0px;
        top: 0px;
    }

    25% {
        left: 100px;
        top: 0px;
    }

    50% {
        left: 100px;
        top: 100px;
    }

    75% {
        left: 0px;
        top: 200px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}

@keyframes moveShap2 {
    0% {
        right: 0px;
        top: 0px;
    }

    25% {
        right: 100px;
        top: 0px;
    }

    50% {
        right: 100px;
        top: 100px;
    }

    75% {
        right: 0px;
        top: 200px;
    }

    100% {
        right: 0px;
        top: 0px;
    }
}

/* Heartbeat frame*/
@-webkit-keyframes heartbeat {
    to {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@keyframes heartbeat {
    to {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

/* Rotation frame*/
@keyframes rotation {
    to {
        transform: rotate(360deg);
    }
}

/* pulseBig */
@keyframes pulseBig {
    0% {
        box-shadow: 0 0 0 0 #fff;
    }

    50% {
        box-shadow: 0 0 0 30px rgba(1, 93, 199, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

/* pulseBig2 */
@keyframes pulseBig2 {
    0% {
        box-shadow: 0 0 0 0 #1b83c5;
    }

    50% {
        box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

/* pulseBig3 */
@keyframes pulseBig3 {
    0% {
        box-shadow: 0 0 0 0 $theme-color;
    }

    50% {
        box-shadow: 0 0 0 10px rgba(1, 93, 199, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

/* pulseBig2 */
@keyframes pulseBig4 {
    0% {
        box-shadow: 0 0 0 0 #fc9ba4;
    }

    50% {
        box-shadow: 0 0 0 10px rgba(1, 93, 199, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

//scale-up-01
.scaleUp1 {
    -webkit-animation-name: scaleUp1;
    animation-name: scaleUp1;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes scaleUp1 {
    0% {
        -webkit-transform: translateY(1);
        transform: translateY(1);
    }

    20% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    40% {
        -webkit-transform: translateY(1);
        transform: translateY(1);
    }

    80% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    100% {
        -webkit-transform: translateY(1);
        transform: translateY(1);
    }
}

@keyframes scaleUp1 {
    0% {
        -webkit-transform: translateY(1);
        transform: translateY(1);
    }

    20% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    40% {
        -webkit-transform: translateY(1);
        transform: translateY(1);
    }

    80% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    100% {
        -webkit-transform: translateY(1);
        transform: translateY(1);
    }
}

//rail-line
@keyframes rail-line {
    0% {
        transform: translate(-150px);
    }

    100% {
        transform: translate(150px);
        background: $theme-color;
    }
}

@-moz-keyframes rail-line {
    0% {
        transform: translate(-150px);
    }

    100% {
        transform: translate(150px);
        background: $theme-color;
    }
}

@-webkit-keyframes rail-line {
    0% {
        transform: translate(-50px);
    }

    100% {
        transform: translate(50px);
        background: $theme-color;
    }
}


//type
@keyframes type {
    0% {
       width: 0ch;
    }
    100% {
        width: 100%;
    }
}
@-webkit-keyframes type {
    0% {
       width: 0ch;
    }
    100% {
        width: 100%;
    }
}



//top-to-bottom
@keyframes runs {
    0% {
        transform: translateY(0) rotate(90deg);
    }

    50% {
        transform: translateY(300px) rotate(90deg);
    }

    100% {
        transform: translateY(-300px) rotate(180deg);
    }
}
@-webkit-keyframes runs {
    0% {
        transform: translateY(0) rotate(90deg);
    }

    50% {
        transform: translateY(300px) rotate(90deg);
    }

    100% {
        transform: translateY(-300px) rotate(180deg);
    }
}
@-moz-keyframes runs {
    0% {
        transform: translateY(0) rotate(90deg);
    }

    50% {
        transform: translateY(300px) rotate(90deg);
    }

    100% {
        transform: translateY(-300px) rotate(180deg);
    }
}
@-o-keyframes runs {
    0% {
        transform: translateY(0) rotate(90deg);
    }

    50% {
        transform: translateY(300px) rotate(90deg);
    }

    100% {
        transform: translateY(-300px) rotate(180deg);
    }
}


//color-change-on
@keyframes zoomIncolor {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
        background: $theme-color;
        opacity: 0.5;
    }
    100% {
        transform: scale(1.3);
        background: $theme-color;
        opacity: 0.8;
    }
}
@-webkit-keyframes zoomIncolor {
   0% {
       transform: scale(0.8);
   }

   50% {
       transform: scale(1);
       background: $theme-color;
       opacity: 0.5;
   }

   100% {
       transform: scale(1.3);
       background: $theme-color;
       opacity: 0.8;
   }
}
@-moz-keyframes zoomIncolor {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
        background: $theme-color;
        opacity: 0.5;
    }

    100% {
        transform: scale(1.3);
        background: $theme-color;
        opacity: 0.8;
    }
}


//squareMove

@keyframes squareMove {
    0% {
        transform: translateX(0px) rotate(0deg);
    }

    33% {
        transform: translateY(-100px) rotate(180deg);
    }

    66% {
        transform: translateX(200px) rotate(-90deg);
    }

    100% {
        transform: translateX(0px) rotate(0deg);
    }
}

@-webkit-keyframes squareMove {
    0% {
        transform: translateX(0px);
    }

    33% {
        transform: translateY(-100px);
    }

    66% {
        transform: translateX(200px);
    }

    100% {
        transform: translateX(0px);
    }
}

@-moz-keyframes squareMove {
    0% {
        transform: translateX(0px);
    }

    33% {
        transform: translateY(-100px);
    }

    66% {
        transform: translateX(200px);
    }

    100% {
        transform: translateX(0px);
    }
}


