.App {
  overflow-x: hidden;
}

.tab-item {
  background: white;
  border: 1px #d5dadf solid;
  border-left: 5px solid #d5dadf;
  box-shadow: 0 2px 3px rgba(213, 218, 223, 0.35);
  padding: 20px;
  border-radius: 3px;
  transition: all 0.2s ease;
  margin-bottom: 15px;
  cursor: pointer;
}

.tab-item.active {
  box-shadow: 0px 3px 3px 2px rgba(213, 218, 223, 0.35);
  border-left: 5px solid #000067;
}

.tab-content__header {
  color: #000067;
  font-weight: bold;
  font-size: 36px;
}

.tab-content__text {
  font-size: 1.25em;
}

.why-best-logistics {
  background: #f7f9fc;
  padding: 120px 0;
}

.why-best-logistics h2 {
  font-size: 32px;
  color: #000;
}

.app-tra-about-list ul {
  list-style: none;
}

.app-tra-about-list ul li {
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.app-tra-about-list ul li::before {
  content: "\f00c"; /* Font Awesome checkmark */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: -12px;
  color: #000066;
}

.about-copic {
  background-color: #e7f7fe;
  padding: 150px 0;
}

.about-copic h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 25px;
}

.about-copic p {
  font-size: 18px;
  color: #666;
}

.about-copic img {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .about-copic {
    padding: 60px 0;
  }
}
.solClass {
  font-size: 18px;
}

.copic-faq {
  padding-top: 150px;
  padding-bottom: 80px;
}

@media (max-width: 767px) {
  .comic-faq {
    padding-left: 20px;
    padding-right: 20px;
  }

  .theme_btn {
    padding: 22px 15px 22px 15px;
  }
}
.accordion-button {
  background-color: #fff;
  color: #000066;
  font-weight: bold;
}

.accordion-button.collapsed {
  color: #333;
}

.accordion-body {
  background-color: #fff;
  padding: 15px;
  font-size: 16px;
  line-height: 1.6;
}

.accordion-item {
  margin-bottom: 15px;
  border: 1px solid #d5dadf;
}

.faq-wrapper p {
  font-size: 18px;
  color: #666;
}

.faq-wrapper h2 {
  color: #000;
  margin-bottom: 35px;
}

.carousel-section {
  background-color: #f9f9f9;
  padding: 50px 0;
}

.owl-carousel .owl-item img {
  width: 150px;
  padding: 20px;
  border: 1px solid #ccc;
}

.owl-nav {
  display: none;
}

.call-to-action {
  padding: 150px 0;
}

.cta-title h2 {
  font-size: 40px;
  line-height: 1.4;
  color: #333;
}

.cta-btn {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.cta-btn:hover {
  background-color: #333;
  color: #fff;
}

.cta-border {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 5px;
  background-color: #fff;
}

.chat-manager img {
  max-width: 100%;
  height: auto;
}

.footer-layout-2 .subscribe-form input {
  background-color: #f3f3f3;
}

.origin-place p {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

/* .track {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
} */

.track {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
  border: 1px solid #ccc;
  padding: 20px;
}

.responsive-padding {
  padding: 0px 180px; /* Default padding */
}

textarea {
  width: 100%;
  border: 0;
  background-color: #f6f6f6;
  height: 185px;
  padding-left: 16px;
  font-size: 15px;
  color: rgba(24, 21, 44, 0.6);
  display: block;
}

textarea::placeholder {
  font-size: 15px;
  color: rgba(24, 21, 44, 0.6);
}

.service-content {
  margin-top: 160px;
}

.iti {
  width: 100%;
  z-index: 99;
}

.para {
  font-size: 20px;
}

.placeholder::placeholder {
  color: #666666a9;
}

.error {
  color: #cf1a1a;
}

textarea {
  color: #000;
}

@media (max-width: 768px) {
  .responsive-padding {
    padding: 0px 20px; /* Smaller padding for mobile */
  }

  .service-content {
    margin-top: 140px;
  }
}

@media (max-width: 576px) {
  .responsive-padding {
    padding: 0px 10px; /* Even smaller padding for extra small devices */
  }
}

.right-arrow {
  color: #fff;
}

.Toastify__toast-container--top-right {
  z-index: 9999999;
}

/* Wrapper for currency symbol and input */
.currency-input-container {
  display: flex;
  align-items: center;
}

/* Currency symbol styling */
.currency-symbol {
  padding: 12px 12px;
  background-color: #f3f4f6; /* Light gray background for separation */
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #333;
}

/* Input styling for consistency with symbol */
.currency-input-container .qty-input {
  width: 100%;
  border-radius: 0 4px 4px 0;
}
.currency-input-container .quantity {
  width: 100%;
  border-radius: 0 4px 4px 0;
  margin-bottom: 0px !important;
}

.prefer-currency .quantity {
  z-index: 100;
}

.modal-header h5 {
  color: #fff;
}

.modal-bg {
  background-color: #000066;
}

.footer-layout-2 .subscribe-form {
  width: 100%;
}

.footer-layout-2 .subscribe-form .input-wrapper {
  display: flex;
  position: relative;
  height: 65px;
}

.footer-layout-2 .subscribe-form input {
  flex: 1;
  color: rgba(6, 3, 21, 1);
  font-size: 16px;
  font-family: "Segoe UI", sans-serif;
  font-weight: 400;
  border: 0;
  padding: 0 70px 0 50px; /* Increased right padding to accommodate the button */
  width: 100%;
  height: 100%;
}

.footer_sub_btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  background-color: #1b83c5;
  padding: 0 16px;
  z-index: 1;
  border-radius: 0 4px 4px 0; /* Changed to right side rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
}

.fot_sub_btn img {
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
}

.sidebar-menu li a {
  transition: all 0.3s;
}

.sidebar-menu li a:focus,
.sidebar-menu li a:hover {
  color: #1b83c5;
}

.sidebar-menu li a {
  display: flex;
  align-items: center;
}

.react-tel-input input.custom-phone-input {
  width: 100%;
  border: 0;
  background-color: #f6f6f6;
  height: 65px;
  padding-left: 50px;
  font-size: 15px;
  border-radius: 0px;
}

@media (max-width: 768px) {
  .mainTruck {
    display: none;
  }
}
