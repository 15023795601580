.search-table {
  display: flex;
  gap: 12px;
  align-items: center;
}

.search-text {
  outline: none;
  border: none;
  background-color: none;
}

.search-table input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-generic {
  padding: 2.5px 20px;
  border-radius: 30px;
  outline: none;
}

.pay-btn {
  color: #fff;
  background-color: #1b83c5;
  border: none;
  transition: all 0.33;
}

.decline-btn {
  color: #fff;
  background-color: rgba(239, 68, 68, 1);
  border: none;
  transition: all 0.33;
}

.view-btn {
  color: #1b83c5;
  background-color: #fff;
  border: 1px solid #1b83c5;
  transition: all 0.33;
}

.view-btn:hover {
  background-color: #1b83c5;
  color: #fff;
  border: none;
}

.download-btn {
  color: #010066;
  background-color: #fff;
  border: 1px solid #010066;
  transition: all 0.33;
}

.download-btn:hover {
  background-color: #010066;
  color: #fff;
  border: none;
}

.print-btn {
  color: #ff9500;
  background-color: #fff;
  border: 1px solid #ff9500;
  transition: all 0.3s;
}

.print-btn:hover {
  border: none;
  background-color: #ff9500;
  color: #fff;
}

.page-item {
  cursor: pointer;
}

.pagination-item {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #212529;
}

.active-pagination {
  background-color: #1b83c5;
  color: #fff;
}

.active-pagination .page-number {
  color: #fff;
}

.page-number {
  outline: none;
  border: none;
  color: #212529;
  background-color: transparent;
}

.table-responsive {
  overflow-x: auto;
  min-width: 100%;
  width: 100%;
}

.table {
  min-width: 800px;
  width: 100%;
}

.c-pill {
  align-items: center;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  width: auto;

  position: relative;
  border-radius: 100px;
  line-height: 1;
  overflow: hidden;
  padding: 0px 12px 0px 20px;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  color: #595959;

  word-break: break-word;

  &:before {
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 6px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
  }
}

.c-pill--success {
  background: #b4eda0;
}
.c-pill--success:before {
  background: #6bc167;
}
.c-pill--warning {
  background: #ffebb6;
}
.c-pill--warning:before {
  background: #ffc400;
}
.c-pill--danger {
  background: #ffd5d1;
}
.c-pill--danger:before {
  background: #ff4436;
}
.c-pill--progress {
  background: #d0d0ff;
}
.c-pill--progress:before {
  background: #000067;
}

.text-italics {
  font-weight: 600;
}
