.quotation-container {
  margin: 0 auto;
  margin-top: 52px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.quote-title {
  font-weight: 700;
  display: flex;
  height: 40px;
  align-items: center;
  color: #fff;
  font-size: 24px;
  padding: 8px;
  background-color: #1585c6;
  margin-left: -20px;
}

.quote-info p {
  text-align: right;
  font-weight: 600;
  color: #1a1a1a;
}

.small-line {
  height: calc(100% + 16px);
  width: 1px;
  background-color: #fff;
  margin-left: 13px;
  margin-right: 8px;
}

.big-line {
  height: calc(100% + 16px);
  width: 2px;
  background-color: #fff;
  margin-right: 20px;
}

.quotation-header {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  align-items: flex-end;
}

.quote-details {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.table-title {
  margin-left: 20px;
  margin-right: 20px;
}

.table-body {
  margin: 0 20px;
}

.table-title p {
  font-weight: 700;
  color: #1a1a1a;
}

.totals {
  text-align: right;
  margin: 20px 0;
}

.signature {
  margin-top: 20px;
  width: 180px;
}

.signature p {
  font-weight: 700;
  color: #1a1a1a;
}

.bold-text {
  font-weight: 700;
  color: #1a1a1a;
}

section {
  padding: 20px;
  padding-bottom: 0px;
}

.line-break {
  background-color: #1585c6;
  width: calc(100% + 40px);
  height: 1px;
  margin: 14px -20px;
}

.two {
  height: 2px;
}

.black {
  background-color: #1a1a1a;
  width: 100%;
  height: 1px;
  margin-top: 6px;
}

.total-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-balance {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 12px;
}

.now-btn {
  border: 1px solid #1585c6;
  padding: 4px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.now-btn p {
  color: #1585c6;
  font-weight: 700;
}

.balance-title {
  display: flex;
  gap: 42px;
  background-color: #1585c6;
  padding: 4px 10px;
}

.balance-title p {
  color: #fff;
  font-weight: 700;
}

.table-rate {
  margin-left: 90px;
}

.totals p {
  font-weight: 700;
  color: #1a1a1a;
}

.instructions {
  margin-top: 20px;
}

.status-tag {
  padding: 4px 10px;
  font-weight: 700;
  font-size: 28px;
  border-radius: 8px;
}

.pending {
  border: 1px solid #face21;
  color: #face21;
}

.paid {
  border: 1px solid#6bc167;
  color: #6bc167;
}

.unpaid {
  border: 1px solid #ff4436;
  color: #ff4436;
}

/* .c-pill--success {
  background: #b4eda0;
}
.c-pill--success:before {
  background: #6bc167;
}
.c-pill--warning {
  background: #ffebb6;
}
.c-pill--warning:before {
  background: #ffc400;
}
.c-pill--danger {
  background: #ffd5d1;
}
.c-pill--danger:before {
  background: #ff4436;
}
.c-pill--progress {
  background: #d0d0ff;
}
.c-pill--progress:before {
  background: #000067;
} */
