
//font-weight
.fw-bold {
    font-weight: 700;
}
.fw-semibold {
    font-weight: 600;
}
.fw-medium {
    font-weight: 500;
}
.fw-normal {
    font-weight: 400;
}
.fw-light {
    font-weight: 300;
}


//font-size

.fs-20 {
    font-size: 20px;
}
.fs-16 {
    font-size: 20px;
}
.fs-14 {
    font-size: 20px;
}
.fs-12 {
    font-size: 20px;
}