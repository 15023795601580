.proceed-btn {
  visibility: hidden;
  text-decoration: none !important;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.6s ease-in;
}

.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-10px);
}

.card:hover .proceed-btn {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
  text-decoration: none !important;
}

.outline-btn {
  text-decoration: none;
  color: #fff;
  background-color: #060315;
  padding: 10px 20px;
  border: 1px solid #000;
}
