.purple-bg {
  background-color: #b5d6f7;
  position: relative;
}

.logo {
  width: 150px;
}

.pageImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.globe-img {
  max-width: 100%;
  height: auto;
}

.login-form {
  width: 75%;
}

.login-form h1 {
  color: #000080;
  font-weight: 600;
  font-size: 28px;
}

.form-label {
  font-weight: 600;
  font-size: 20px;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #000;
  padding: 20px 10px;
}

.forgot-password {
  color: #010166;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
}

.btn-primary {
  background-color: #010166;
  border-color: #010166;
  border-radius: 10px;
  padding: 20px 10px;
  font-weight: 600;
  font-size: 20px;
}

.btn-outline-secondary {
  border-radius: 0;
}

.google-icon {
}

.create-account {
  color: #010166;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}

.login-with-google {
  color: #000;
  font-weight: 700;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid #000;
}

@media (max-width: 760px) {
  .pageImage {
    display: none;
  }

  .login-form {
    width: 90%;
  }
}
