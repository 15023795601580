.dashboard-title {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 5px;
}

.table-head {
  background-color: #000666;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.list-group-item {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.list-group-item:hover {
  transform: scale(1.01);
}

div.dashboard-title span {
  /* display: inline-block; */
  width: 60%;
  font-size: 18px;
}

 .edit-div{
    width: 60%;
  }

@media (max-width: 768px) {
  div.dashboard-title span {
    width: 100%;
  }

  .chart-svg {
    display: none;
  }

  .edit-div{
    width: 100%;
    margin-bottom: 12px;
  }
}
