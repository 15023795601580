.purple-bg {
  background-color: #b5d6f7;
  position: relative;
  height: 100vh;
}

.left {
  position: fixed;
  top: 0;
  left: 0;
}

.right {
  margin-left: 50%;
  padding: 50px 0;
}

.logo {
  width: 150px;
}

.pageImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -5%;
}

.globe-img {
  width: 300px;
  height: auto;
}

.login-form {
  width: 75%;
}

.login-form h1 {
  color: #000080;
  font-weight: bold;
}

.form-label {
  font-weight: 600;
  font-size: 20px;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #000;
  padding: 20px 10px;
}

.btn-primary {
  background-color: #010166;
  border-color: #010166;
  border-radius: 10px;
  padding: 20px 10px;
  font-weight: 600;
  font-size: 20px;
}

.btn-outline-secondary {
  border-radius: 0;
}

.google-icon {
}

.login-with-google {
  color: #000;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid #000;
}

@media (max-width: 760px) {
  .pageImage {
    display: none;
  }

  .purple-bg {
    height: fit-content;
  }

  .login-form {
    width: 90%;
  }

  .purple-bg {
    margin-bottom: 10px;
  }

  .left {
    position: relative;
  }

  .right {
    margin-left: 0;
    padding: 10px;
  }
}
