/* General Page Title Styling
.page-title-area {
  background-color: #e0f7fa;
  padding: 2rem 0;
  text-align: center;
  color: #007bff;
}

.page-title-wrapper h4.styled-text {
  color: #007bff;
  font-size: 1.5rem;
  font-weight: 600;
} */

.page-title-wrapper h5.page-title {
  font-size: 2.5rem;
  color: #333;
}

/* Input and Button Styling */
.track-parcels-input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.track-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #007bff;
  border-radius: 5px 0 0 5px;
  font-size: 1rem;
  outline: none;
}

.track-button {
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0 5px 5px 0;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.track-button:hover {
  background-color: #0056b3;
}

/* Parcel Information Card */
.parcel-info-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 1.5rem;
  margin-top: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}

.parcel-info-card h3 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.parcel-info-card p {
  color: #555;
}

.parcel-info-card .parcel-status {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: bold;
  margin-top: 1rem;
}

/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.c-pill {
  align-items: center;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  width: auto;

  position: relative;
  border-radius: 100px;
  line-height: 1;
  overflow: hidden;
  padding: 0px 12px 0px 20px;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  color: #595959;

  word-break: break-word;

  &:before {
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 6px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
  }
}

.c-pill--success {
  background: #b4eda0;
}
.c-pill--success:before {
  background: #6bc167;
}
.c-pill--warning {
  background: #ffebb6;
}
.c-pill--warning:before {
  background: #ffc400;
}
.c-pill--danger {
  background: #ffd5d1;
}
.c-pill--danger:before {
  background: #ff4436;
}
.c-pill--progress {
  background: #d0d0ff;
}
.c-pill--progress:before {
  background: #000067;
}

.Toastify__toast-container--top-right {
  z-index: 9999999;
}
