.slider__content h5 {
  font-size: 1.5rem;
  color: rgb(28, 133, 199);
  margin-bottom: 20px;
}

.main-title {
  font-size: 3.3rem;
  color: rgb(0, 0, 102);
  text-transform: uppercase;
  white-space: pre-line;
}

.slider__content h5.bottom-h5 {
  color: rgb(102, 102, 102);
}

/* Animations */
@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
    rotate: 45deg;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    rotate: 0deg;
  }
}

/* H5 animations */
.fade-in-from-left {
  opacity: 0;
  animation: fadeInFromLeft 1s ease-out forwards;
}

.delay-2s {
  animation-delay: 2s;
}

@keyframes fadeInLetter {
  0% {
    opacity: 0;
    transform: translateX(1000px) rotate(-180deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0deg);
  }
}

@keyframes fadeInLetterSmall {
  0% {
    opacity: 0;
    transform: translateX(-1000px) rotate(-180deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0deg);
  }
}

.letter {
  display: inline-block;
  opacity: 0;
  animation: fadeInLetter 0.8s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

.letter-small {
  display: inline-block;
  opacity: 0;
  animation: fadeInLetterSmall 0.8s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

.main-header{
  padding: 165px 20px 200px 20px;
}

@media (max-width: 996px) {
  .main-title {
    font-size: 3rem;
  }
}

@media (max-width: 780px) {
  .main-title {
    font-size: 2rem;
  }

  .slider__content h5 {
    font-size: 1.2rem;
  }

}

@media (max-width: 580px) {
  .main-title {
    font-size: 1.5rem;
  }

  .slider__content h5 {
    font-size: 1rem;
  }

  .main-header{
    padding: 100px 20px 200px 20px;
  }

  .slider-container{
    padding: 0;
  }
  
}
