//quote-info-wrapper
.quote-info-wrapper {
    background-color: $white;
    box-shadow: 0px 0px 30px 0px rgba(229, 230, 232, 0.72);
    padding: 60px 5px 60px 25px;
    @media #{$xs} {
        padding: 35px ​5px 10px 25px;
    }
   & p {
        color: $heding;
        font-size: 16px;
        text-transform: uppercase;
        font-family: $Segoe-ui;
        margin-bottom: 10px;
        display: inline-block;
    }
   & .nice-select {
        width: 92%;
        background-color: #f6f6f6;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border: 1px solid transparent;
        height: 65px;
        line-height: 65px;
        @media #{$xs} {
            margin-bottom: 15px;
        }
        & .list {
            width: 100%;
        }
        &.open {
            background-color: transparent;
            border-color: $heding;
        }
    }
    .align-bottom-content {
        transform: translateY(59px);
        -webkit-transform: translateY(59px);
        -moz-transform: translateY(59px);
        -ms-transform: translateY(59px);
        -o-transform: translateY(59px);
        @media #{$md} {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
        }
    }
}
.from-place {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media #{$xs} {
        display: block;
    }
    @media #{$sm} {
        display: grid;
    }
}

.quotes-title {
    font-size: 30px;
    margin-bottom: 25px;
    @media #{$xs} {
        font-size: 26px;
    }
}

.q-style1 {
    position: relative;
    z-index: 1;
    /* Chrome, Safari, Edge, Opera */
   & input {
        width: 100%;
        border: 0;
        background-color: #f6f6f6;
        height: 65px;
        padding-left: 16px;
        font-size: 15px;
        color: rgba(24, 21, 44, 0.6);
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
   & .qut-btn {
        border: 0;
        background: 0;
        padding: 0;
        font-size: 11px;
        position: absolute;
        z-index: 2;
        top: 0;
    }
   & .quantity-plus {
        &.qut-btn {
            right: 24px;
            top: 10px;
        }
    }
   & .quantity-minus {
        &.qut-btn {
            right: 24px;
            top: auto;
            bottom: 10px;
        }
    }
} 