/* Fullscreen slide bar */
.slide-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

/* Show slide bar when active */
.slide-bar.open {
  transform: translateX(0);
}

/* Mobile menu styles */
.side-mobile-menu {
  padding: 20px;
}

.side-mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-mobile-menu ul li {
  margin-bottom: 15px;
}

/* Full-width create account button */
.theme_btn_full {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #000066;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}

/* Ghost button styles */
.theme_btn_border {
  border: 2px solid #060315;
  background-color: transparent;
  color: #060315;
}

/* Overlay for mobile menu */
.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: none;
}

/* Show overlay when menu is open */
.slide-bar.open ~ .body-overlay {
  display: block;
}

@media (max-width: 992px) {
  .side-mobile-menu ul li a {
    color: #444;
  }
}
