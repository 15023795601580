.app-container {
  display: flex;
  background-color: #e7ecf8;
  color: #3e3f43;
  width: 100%;
  min-height: 100vh;
}

.sidebar {
  position: fixed; /* Fix the sidebar to the left */
  top: 0;
  left: 0;
  height: 100%; /* Ensure it covers the full height */
  width: 75px; /* Default width when collapsed */
  background-color: #fff;
  color: #40475c;
  transition: width 0.3s ease;
  overflow-x: hidden;
  z-index: 999; /* Keep it on top of the content */
}

.sidebar.open {
  width: 350px; /* Adjust width for open state */
}

.logo-div {
  display: none;
}

.main {
  margin-left: 75px; /* Leave space for the sidebar */
  width: calc(100% - 75px);
  margin-bottom: 40px;
  transition: margin-left 0.3s ease;
}

.main.sidebar-open {
  margin-left: 350px;
  width: calc(100% - 350px);
}

.toggle-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;

  text-align: right;
}

.toggle-icon {
  cursor: pointer;
  transition: all 0.3s;
}

.sidebar-header {
  padding: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 15px;
  margin: 0;
}

.sidebar-menu li {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sidebar-menu li.active {
  filter: invert(42%) sepia(85%) saturate(468%) hue-rotate(172deg)
    brightness(101%) contrast(98%);
  color: #fff;
}

.sidebar-menu li i {
  margin-right: 10px;
  filter: invert(41%) sepia(17%) saturate(674%) hue-rotate(182deg)
    brightness(95%) contrast(89%);
}

.icon {
  filter: invert(41%) sepia(17%) saturate(674%) hue-rotate(182deg)
    brightness(95%) contrast(89%);
  cursor: pointer;
}

.sidebar-menu li:hover i {
  filter: invert(42%) sepia(85%) saturate(468%) hue-rotate(172deg)
    brightness(101%) contrast(98%);
}

.main-content {
  flex-grow: 1;
  padding: 40px;
  transition: margin-left 0.3s ease;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.top-nav {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  background-color: #fff;
  width: 100%;
  align-items: center;
}

.page-title {
  font-weight: 600;
  font-size: 24px;
}

.second-section {
  background-color: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.pending-payment {
  font-weight: 600;
}

.quote-info-wrapper-div .nice-select {
  width: 92%;
  background-color: #f6f6f6;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: 1px solid transparent;
  height: 65px;
  line-height: 65px;
}

.dashboard-title span {
  margin-top: 6px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0em;
  vertical-align: 0em;
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
}

.page-title-wrapper h5.page-title {
  font-size: 2.5rem;
  color: #333;
}

/* Input and Button Styling */
.track-parcels-input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.track-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #007bff;
  border-radius: 5px 0 0 5px;
  font-size: 1rem;
  outline: none;
}

.track-button {
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0 5px 5px 0;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.track-button:hover {
  background-color: #0056b3;
}

/* Parcel Information Card */
.parcel-info-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 1.5rem;
  margin-top: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}

.parcel-info h3 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.parcel-info p {
  color: #555;
}

.parcel-status {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: bold;
  margin-top: 1rem;
}

/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.c-pill {
  align-items: center;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  width: auto;

  position: relative;
  border-radius: 100px;
  line-height: 1;
  overflow: hidden;
  padding: 0px 12px 0px 20px;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  color: #595959;

  word-break: break-word;

  &:before {
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 6px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
  }
}

.c-pill--success {
  background: #b4eda0;
}
.c-pill--success:before {
  background: #6bc167;
}
.c-pill--warning {
  background: #ffebb6;
}
.c-pill--warning:before {
  background: #ffc400;
}
.c-pill--danger {
  background: #ffd5d1;
}
.c-pill--danger:before {
  background: #ff4436;
}
.c-pill--progress {
  background: #d0d0ff;
}
.c-pill--progress:before {
  background: #000067;
}

.Toastify__toast-container--top-right {
  z-index: 9999999;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
  }

  .logo-div {
    display: inline;
  }

  .main-content {
    padding: 20px;
  }

  .sidebar.open {
    left: 0; /* Slide the sidebar into view */
  }
}