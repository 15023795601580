

  .horizontal-scroll {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.horizontal-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.tab-button {
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.tab-button:hover {
  color: #000067;
}

.tab-button.active {
  color: #000067;
}

.placeholder-glow {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .tab-button {
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
    min-width: 70px;
  }
  
  .tab-button svg {
    width: 20px;
    height: 20px;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  p {
    font-size: 0.9rem;
  }
  
  .theme_btn {
    font-size: 0.9rem;
    padding: 0.8rem 1.8rem;
  }
}

@media (max-width: 576px) {
  .tab-button {
    font-size: 0.75rem;
    padding: 0.4rem 0.6rem;
    min-width: 60px;
  }
  
  .tab-button svg {
    width: 18px;
    height: 18px;
  }
  
  h3 {
    font-size: 1.25rem;
  }
  
  p {
    font-size: 0.875rem;
  }
  
 
}