/*--
    - Overlay
------------------------------------------*/

.overlay-soft {
    position: relative;
    z-index: 1;
    &::after {
        content: '';
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
} 
[data-overlay] {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
    }
}

/*-- Overlay Color --*/
[data-overlay="light"] {
    &::before {
        background-color: $white;
    }
}
[data-overlay="dark"] {
    &::before {
        background-color: $theme-color;
    }
}

/*-- Overlay Opacity --*/
[data-opacity="1"] {
    &::before {
        opacity: 0.1;
    }
}
[data-opacity="2"] {
    &::before {
        opacity: 0.2;
    }
}
[data-opacity="3"] {
    &::before {
        opacity: 0.3;
    }
}
[data-opacity="4"] {
    &::before {
        opacity: 0.4;
    }
}
[data-opacity="5"] {
    &::before {
        opacity: 0.5;
    }
}
[data-opacity="6"] {
    &::before {
        opacity: 0.6;
    }
}
[data-opacity="7"] {
    &::before {
        opacity: 0.7;
    }
}
[data-opacity="8"] {
    &::before {
        opacity: 0.8;
    }
}
[data-opacity="9"] {
    &::before {
        opacity: 0.9;
    }
}