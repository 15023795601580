//slider-custom-container
.intro-custom-container {
    max-width: 1485px;
    margin: 0 auto;
}


//services-custom-container
.services-custom-container {
    max-width: 1605px;
    margin: 0 auto;
}
//services-custom-container-2
.services-custom-container-2 {
    max-width: 1420px;
    margin: 0 auto;
}
//testimonial-custom-container
.testimonial-custom-container {
    max-width: 1250px;
    margin: 0 auto;
}
//page-title-custom-container
.page-title-custom-container {
    max-width: 1580px;
    margin: 0 auto;
}


